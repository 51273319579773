<template>
  <!--Main Container-->
  <main class="h-screen flex flex-col justify-between">
    <!--Header-->
    <transition name="fade" mode="out-in">
      <Header v-if="$route.name !== 'home'"/>
    </transition>
    <!-- App -->
    <router-view v-slot="{Component}">
      <transition name="fade" mode="out-in">
        <component :is="Component"/>
      </transition>
    </router-view>
    <!-- Footer -->
    <Footer v-if="isFooterVisible"/>
  </main>
</template>

<script>
import Footer from "@/layouts/footer";
import Header from "@/layouts/header";
import {computed} from "vue";
import {useRoute} from "vue-router"

export default {
  name: "master",
  components: {Header, Footer},
  setup() {

    const route = useRoute();
    const routesWithoutFooter = ['home','portfolio']

    const isFooterVisible = computed(() => {
      return !routesWithoutFooter.includes(route.name)
    });

    return {
      isFooterVisible,
    }
  }
}
</script>

<style lang="scss" scoped>

</style>