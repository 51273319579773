<template>
  <header ref="header" :class="{solid:solidState,dark:isDark}">
    <div class="inner">
      <div class="flex flex-row items-center">
        <router-link to="/">
          <div class="w-5 h-5 cursor-pointer mr-4">
            <svg width="1.25rem" height="1.25rem" viewBox="0 0 969 1036" version="1.1" xmlns="http://www.w3.org/2000/svg"
                 xmlns:xlink="http://www.w3.org/1999/xlink">
              <g id="Page-1" stroke="none" stroke-width="1" fill="white" fill-rule="evenodd">
                <g id="logo" transform="translate(-64.000000, -22.000000)" fill="#010C17" fill-rule="nonzero">
                  <path
                      d="M683.325,1049 L683.325,883.7 L246.875,883.7 L246.875,22.4 L64.175,22.4 L64.175,1049 L683.325,1049 Z M916.775,1057.7 C948.675,1057.7 975.983333,1046.58333 998.7,1024.35 C1021.41667,1002.11667 1032.775,975.533333 1032.775,944.6 C1032.775,913.666667 1021.175,887.566667 997.975,866.3 C974.775,845.033333 947.708333,834.4 916.775,834.4 C884.875,834.4 857.566667,845.516667 834.85,867.75 C812.133333,889.983333 800.775,916.566667 800.775,947.5 C800.775,962.966667 803.916667,977.466667 810.2,991 C816.483333,1004.53333 824.941667,1016.13333 835.575,1025.8 C846.208333,1035.46667 858.533333,1043.2 872.55,1049 C886.566667,1054.8 901.308333,1057.7 916.775,1057.7 Z"
                      id="L."></path>
                </g>
              </g>
            </svg>
          </div>
        </router-link>
        <Breadcrumb/>
      </div>
    </div>
  </header>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import {onMounted, onUnmounted, computed, ref,} from "vue";
import {useRoute} from "vue-router"

export default {
  name: "custom_header",
  components: {Breadcrumb},
  setup() {

    const header = ref(null)
    const solidState = ref(false)
    const route = useRoute()
    const darkRoutes = ref(['about', 'bitelife', 'timerly'])

    onMounted(() => {
      handleScroll();
      document.addEventListener('scroll', handleScroll)
    })

    onUnmounted(() => {
      document.removeEventListener('scroll', handleScroll)
    })

    const isDark = computed(() => {
      return darkRoutes.value.includes(route.name)
    });

    /**
     * @desc Handle scroll function
     */
    const handleScroll = () => {

      const scrollY = window.scrollY
      solidState.value = scrollY > 10
    }
    return {
      header,
      solidState,
      isDark
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  @apply fixed w-full top-0 left-0 right-0 px-6 py-4 transition transition-all duration-200;
  z-index: 9999;

  .inner {
    @apply flex flex-row justify-between;

    path{
      @apply transition transition-all duration-100;
      fill: $font-color-white;
    }
  }

  &.dark {
    color: $font-color-dark;
    path{
      fill: $font-color-dark;
    }
  }

  &.solid {
    @apply shadow-2xl;
    color: $font-color-white;
    background-color: rgba(16, 42, 67, 0.9);
    path{
      fill: $font-color-white;
    }
  }
}
</style>