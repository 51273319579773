<template>
  <div class="breadcrumb">
    <transition-group name="scale" mode="out-in">
    <span class="breadcrumb__element" v-for="(item,index) in levelList" :key="item.path">
      <span v-if="item.redirect==='noRedirect'||index===levelList.length-1" class="font-medium">{{
          item.meta.title
        }}</span>
      <a v-else @click.prevent="onLinkClick(item)" class="font-bold cursor-pointer">{{ item.meta.title }}</a>
      <span v-if="index!==levelList.length-1" class="px-2">/</span>
    </span>
    </transition-group>
  </div>
</template>

<script>
import {onMounted, ref, watch} from "vue";
import {useRoute, useRouter} from 'vue-router';

export default {
  name: "Breadcrumb",
  setup() {

    const levelList = ref(null)
    const route = useRoute();
    const router = useRouter();

    onMounted(() => {
      getBreadcrumb()
    })

    /**
     * WATCH
     */
    watch(() => route.name, () => {
      getBreadcrumb()
    });

    /**
     * @desc Get breadcrumb
     */
    function getBreadcrumb() {
      let matched = route.matched.filter(item => item.meta && item.meta.title)
      levelList.value = matched.filter(item => item.meta && item.meta.title && item.meta.breadcrumb !== false);
    }

    /**
     * @desc On link click
     * @param item
     */
    function onLinkClick(item) {
      const {redirect} = item;
      if (redirect) {
        router.push(redirect);
        return;
      }
      router.push({path: item.path});
    }

    return {
      levelList,
      onLinkClick
    }
  }
}
</script>

<style lang="scss" scoped>
.breadcrumb {
  @apply inline-flex transition-all transition duration-200;

  &__element{
    @apply transition-all transition duration-200 text-lg;
  }
}
</style>