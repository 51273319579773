<template>
  <footer>
    <!-- Call to action -->
    <section class="call-to-action" v-if="$route.name!=='contact'">
    <span class="m-auto text-center">Would you find it interesting to work together? <br>
      <router-link to="/contact">
        <span>Contact</span>
      </router-link>
      me
    </span>
    </section>

    <section class="rights-and-contacts">
      <div class="justify-center flex items-center md:justify-start">
        <span>© 2021 - ALL RIGHTS RESERVED</span>
        <!--<a class="ml1vw" style="color:#f4f4f4" href="">Legal Notice</a>-->
      </div>
      <div class="flex justify-center md:justify-end">
        <div class="grid grid-cols-4 gap-6">
          <SocialLink type="linkedin-white" size="8"/>
          <SocialLink type="twitter-white" size="8"/>
          <SocialLink type="github-white" size="8"/>
          <SocialLink type="telegram-white" size="8"/>
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
import SocialLink from "@/components/SocialLink";

export default {
  name: "custom_footer",
  components: {SocialLink},
}
</script>

<style lang="scss" scoped>

footer {
  .call-to-action {
    @apply flex flex-wrap items-center px-2 py-24 font-medium text-2xl;

    @screen sm {
      @apply p-36;
    }

    a {
      background: -webkit-linear-gradient(#f19872, #e86c9a);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: bold;
    }
  }


  .rights-and-contacts {
    @apply inline-flex w-full font-bold text-base p-8 grid grid-cols-1 gap-8;
    background: $footer-background;

    @screen sm {
      @apply grid-cols-2;
    }

    img {
      @apply mx-2 w-6;
    }
  }

}
</style>